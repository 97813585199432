'use client';

import { FunctionComponent } from 'react';
import { PageService } from '@/services/isomorphic/PageService';
import type { IPage } from '@/services/models/Page';

export interface IPageProps {
  /**
   * The page object that should come from the page file.
   */
  page: IPage;
}

/**
 * Receives the page object and then stores it on the `PageService`.
 * This allow the client components to have access to the same items cached in the
 * react cache.
 */
export const PageComponent: FunctionComponent<IPageProps> = ({ page }) => {
  PageService.page = page;
  return null;
};

export default PageComponent;
